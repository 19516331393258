// Generated by Framer (575e68f)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["IXkmtR02Z"];

const serializationHash = "framer-xgKjA"

const variantClassNames = {IXkmtR02Z: "framer-v-smw7xv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "IXkmtR02Z", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 645, intrinsicWidth: 708, pixelHeight: 645, pixelWidth: 708, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/4KnlJAF7JJOu4WTPLkQU3vwgL70.png", srcSet: "https://framerusercontent.com/images/4KnlJAF7JJOu4WTPLkQU3vwgL70.png?scale-down-to=512 512w,https://framerusercontent.com/images/4KnlJAF7JJOu4WTPLkQU3vwgL70.png 708w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-smw7xv", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"IXkmtR02Z"} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xgKjA.framer-frsdk1, .framer-xgKjA .framer-frsdk1 { display: block; }", ".framer-xgKjA.framer-smw7xv { height: 645px; overflow: visible; position: relative; width: 708px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 645
 * @framerIntrinsicWidth 708
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerp8HddJcaL: React.ComponentType<Props> = withCSS(Component, css, "framer-xgKjA") as typeof Component;
export default Framerp8HddJcaL;

Framerp8HddJcaL.displayName = "Logo";

Framerp8HddJcaL.defaultProps = {height: 645, width: 708};

addFonts(Framerp8HddJcaL, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})